<template>
  <div class="row">

    <Print
      :data="data"
      :paymentRemuneration="paymentRemuneration"
      :pieceRemuneration="pieceRemuneration"
      :taxRemuneration="taxRemuneration"
      :tableDisplay="tableDisplay"
      :employee="employee"
       :subTotalPayment="subTotalPayment"
          :subTotalPiece="subTotalPiece"
          :subTotalTax="subTotalTax"
          :address="address"
      v-if="dataLoad == true"
    />

    <!-- PDF -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      :filename="'Slip Gaji-'+ data.display_paid_month +'-' +data.employee_name"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <Print
          :data="data"
          :paymentRemuneration="paymentRemuneration"
          :pieceRemuneration="pieceRemuneration"
          :taxRemuneration="taxRemuneration"
          :tableDisplay="tableDisplay"
          :employee="employee"
          :subTotalPayment="subTotalPayment"
          :subTotalPiece="subTotalPiece"
          :subTotalTax="subTotalTax"
          :address="address"
          v-if="dataLoad == true"
        />
      </section>
    </vue-html2pdf>

    <div class="col-md">
      <b-button
        squared
        class="mt-2"
        size="sm"
        variant="primary"
        @click="btnPrintOnClick"
      >Print</b-button>
      <b-button
        squared
        :class="'d-block mt-2'"
        size="sm"
        variant="info"
        @click="btnDownloadOnClick"
      >Download</b-button>
    </div>

  </div>
</template>

<script>

import Print from '@/component/payrolls/Print.vue'
import VueHtml2pdf from "vue-html2pdf"
import module from '@/core/modules/CrudModule.js'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Print,
    VueHtml2pdf
  },

  data() {
    return {
      // Data
      data: {},
      paymentRemuneration:[],
      pieceRemuneration:[],
      taxRemuneration:[],
      subTotalPayment:0,
      subTotalPiece:0,
      subTotalTax:0,
      total:0,
      // items: [],
      tableDisplay:{
        paymentDisplay:false,
        pieceDisplay:false,
        taxDisplay:false,
      },
      employee:{
        position:""
      },
      address:{
        address:"",
        phone:""
      },
      dataLoad:false,
    }
  },

  methods: {

    async getPayment() {
      let res = await module.get(`payrolls/${this.$route.params.id}`)
      this.data = res
      this.getPaymentRemuneration()
      this.getEmployee()
      this.dataLoad = true
    },

    async getPaymentRemuneration(){
      let response = await module.get('remunerations-employee/'+this.data.employee_id)
      // this.paymentRemuneration = response
      // console.log("is edit",this.isEdit);

       this.paymentRemuneration = []
      this.pieceRemuneration = []
      this.taxremuneration = []

      this.subTotal = 0
      this.totalAmmount = 0

      let a,b,c,d,e,f,g,h
      let latestData = []
      let paymentData =[]
      let pieceData = []
      let taxData = [] 
     
      let count = response.length - this.data.remuneration_id.length
        for(a=0;a<response.length;a++){
          if(a < this.data.remuneration_id.length){
            if(response[a].is_tax === 0){
              this.paymentRemuneration.push(response[a])
            }else if(response[a].is_tax === 1){
              this.pieceRemuneration.push(response[a])
            }else if(response[a].is_tax === 2){
              this.taxRemuneration.push(response[a])
            }
          }else{
            latestData.push(response[a])
          }
          // console.log(a,response.length-count);
          // if(a < response.length-count){
          //   if(response[a].is_tax === 0){
          //     paymentData.push(response[a])
          //   }else if(response[a].is_tax === 1){
          //     pieceData.push(response[a])
          //   }else if(response[a].is_tax === 2){
          //     taxData.push(response[a])
          //   }
          // }else{
          //   latestData.push(response[a])
          // }
        }
      
      let dataPush = []
      for(b=0;b<this.paymentRemuneration.length;b++){
        dataPush.push(this.paymentRemuneration[b])
      }

      if(this.pieceRemuneration.length > 0){
        for(f=0;f<this.pieceRemuneration.length;f++){
          dataPush.push(this.pieceRemuneration[f])
        }
      }

      if(this.taxRemuneration.length > 0){
        for(d=0;d<this.taxRemuneration.length;d++){
          dataPush.push(this.taxRemuneration[d])
        }
      }

      if(latestData.length > 0){
        for(h=0;h<latestData.length;h++){
          dataPush.push(latestData[h])
        }
      }


      // if(this.isEdit == true){
      // for(c=0;c<dataPush.length;c++){
      //   dataPush[c].qty = this.data.quantity[c]
      //   dataPush[c].ammountPayroll = this.data.remuneration_ammount[c]*this.data.quantity[c]
      // }
      // }

      // if(this.isEdit == true){
        // if(dataPush.length > this.data.remuneration_id.length){
          // let count = dataPush.length - this.data.remuneration_id.length
          for(g=0;g<dataPush.length-count;g++){
            dataPush[g].qty = this.data.quantity[g]
            dataPush[g].notes = this.data.remuneration_notes[g]
            dataPush[g].ammountPayroll = this.data.remuneration_ammount[g]*this.data.quantity[g]
          }
        // }else{
          // for(c=0;c<dataPush.length;c++){
          //   console.log("dataPush payroll", dataPush[c]);
          //   console.log("dataPush payroll", this.data.quantity[c]);
          //   dataPush[c].qty = this.data.quantity[c]
          //   dataPush[c].ammountPayroll = this.data.remuneration_ammount[c]*this.data.quantity[c]
          // }
        // }
      // }

      this.paymentRemuneration = []
      this.pieceRemuneration = []
       this.taxRemuneration = []

      for(e=0;e<dataPush.length;e++){
        if(dataPush[e].is_tax === 0){
          this.paymentRemuneration.push(dataPush[e])

          if(this.paymentRemuneration.length > 0){
            this.tableDisplay.paymentDisplay = true
          }
        }else if(dataPush[e].is_tax === 1){
          this.pieceRemuneration.push(dataPush[e])

          if(this.pieceRemuneration.length > 0){
            this.tableDisplay.pieceDisplay = true
          }
        }else if(dataPush[e].is_tax === 2){
          this.taxRemuneration.push(dataPush[e])

          if(this.taxRemuneration.length > 0){
            this.tableDisplay.taxDisplay = true
          }
        }
      }
      

      // this.sumSubTotalAmount()
      this.remunerationLoad = true
      this.setSubTotal()
    },

    async getEmployee(){
      let response = await module.get('employees/'+this.data.employee_id)
      this.employee.position = response.position_name
    },

    // async getPaymentList() {
    //   this.items = await module.list(`payrolls-print/${this.$route.params.id}`)
    // },

    setSubTotal(){
      let a,b,c
      for(a = 0; a< this.paymentRemuneration.length;a++){
        this.subTotalPayment = this.subTotalPayment+ this.paymentRemuneration[a].ammount*this.paymentRemuneration[a].qty   
      }
      for(b = 0; b< this.pieceRemuneration.length;b++){
        this.subTotalPiece = this.subTotalPiece + this.pieceRemuneration[b].ammount*this.pieceRemuneration[b].qty 
      }
      for(c = 0; c< this.taxRemuneration.length;c++){
        this.subTotalTax = this.subTotalTax+ this.taxRemuneration[c].ammount*this.taxRemuneration[c].qty 
      }
      this.subTotalTax = (this.subTotalPayment-this.subTotalPiece)*(parseInt(this.subTotalTax)/100)
    },

    async getAddress(){
      let setting = await module.get('website-settings/get-default') 
      this.address.address = setting.address
      this.address.phone = setting.phone
    },

    btnPrintOnClick() {
      this.$htmlToPaper('print')
    },

    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf()
    },

    onProgress($event) {
      console.log("onProgress", $event)
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration")
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event)
    },

  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Penggajian" },
      { title: "Daftar Penggajian", route: "/payrolls/list" },
      { title: "Print" },
    ])
    // Get Data
    this.getPayment()
    this.getAddress()
    // this.getPaymentList()
  },

}
</script>

<style>
</style>